import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { post, get } from "@/api/request";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from 'element-ui';
import '@/assets/font/font.css'
import md5 from 'js-md5';
import cookie from 'vue-cookies';
Vue.config.productionTip = false

Vue.prototype.$md5 = md5;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$cookie = cookie;
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
