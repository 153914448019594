import Vue from "vue";
import axios from "axios";
import qs from "qs";
import cookie from 'vue-cookies'

import { Message } from "element-ui";
import router from "../router";

//接口请求超时时间
axios.defaults.timeout = "5000";
//api地址
const Root = "//case.bz-link.net/s1/"; //http://lhc.bz-link.net/p4 http://case.bz-link.net/s1/

Vue.prototype.rootUrl = Root;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.data instanceof FormData) {
      // Object.assign(config.headers, config.data.getHeaders());
      config.transformRequest= [function (data) {
        return data
      }]
      //data对象转换字符串
      config.data = config.data;
    }else{
      config.header = {
        "content-type": "application/x-www-form-urlencoded"
      };
      config.data = qs.stringify(config.data);
    }
    config.crossDomain = true;
    config.withCredentials = true; //允许跨域携带cookie信息
     //data对象转换字符串
    return config;
    // return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//响应拦截
axios.interceptors.response.use(
  response => {
    //需要弹框
    switch (response.status) {
      //返回数据为空
      case 200:
        if (response.data.length == 0) {
          Message({
            showClose: true,
            message: "暂无数据",
            type: "warning"
          });
        }
        break;
      //下置成功
      case 201:
        Message({
          showClose: true,
          message: response.data,
          type: "success"
        });
        break;
      //操作失败、暂无权限
      case 202:
        Message({
          showClose: true,
          message: response.data,
          type: "warning"
        });
        break;
    }
    return response.data;
  },
  error => {
    switch (error.response.status) {
      //请求参数错误
      case 400:
        Message({
          showClose: true,
          message: error.response.data.messages.error,
          type: "warning"
        });
        break;
      //登陆过期
      case 401:
        Message({
          showClose: true,
          message: error.response.data.messages.error,
          type: "warning"
        });
        cookie.remove('indexPage');
        router.push({
          path: "/login"
        });
        break;
    }
    return Promise.reject(error);
  }
);

//post方法
export function post(url, params = params) {
  url = Root + url;
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

//get方法
export function get(url, params) {
  if (params) {
    var params_str = "";
    for (let i in params) {
      params_str += params[i] + "/";
    }
    params_str = params_str.slice(0, params_str.length - 1);
    url = Root + url + "/" + params_str;
  } else {
    url = Root + url;
  }
  return new Promise((resolve, reject) => {
    axios.get(url).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}
