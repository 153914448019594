<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
  font-size: 12px;
  padding: 0 !important;
}
/*body:hover{*/
/*  overflow-y: auto;*/
/*  margin-left:-6px;*/
/*}*/

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: rgba(16, 84, 220, 0);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}

::-webkit-scrollbar-thumb {
  background: rgba(144, 147, 153, 0.5);
  cursor: pointer;
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-resizer {
  display: none;
}


* {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}


</style>
