import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login'
  },
  {
    path: "/login",
    component: resolve => require(["../views/login.vue"], resolve)
  },
  {
    path: "/home",
    component: resolve => require(["../views/home.vue"], resolve)
  },
  {
    path: "/skipPage",
    component: resolve => require(["../views/skipPage.vue"], resolve)
  },
  {
    path: "/agreement",
    component: resolve => require(["../views/agreement.vue"], resolve)
  },

];

const router = new VueRouter({
  routes
});

export default router;
